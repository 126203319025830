import { Language } from '../common/models';

export const apiUrl = {
  account: {
    login: '/api/Account/authenticate',
    logout: '/api/Account/logout',
    checkForgotPassword: '/api/Account/checkforgotpasswordlink',
    termsAndConditions: '/api/Account/termsandconditions',
    forgotPassword: '/api/Account/forgotpassword',
    setPassword: '/api/Account/setpassword',
    changePassword: '/api/Account/changepassword',
    impersonate: '/api/Account/impersonate',
    exitImpersonate: '/api/Account/exitImpersonate',
    refreshToken: '/api/Account/refreshToken'
  },
  user: {
    getUsers: '/api/User/GetUsers',
      getUserPage: '/api/User/GetUserPage',
      getUserStates: '/api/User/GetUserStates',
      checkEmail: '/api/User/CheckEmail',
      createUser: '/api/User/CreateUser',
      changeUserState: '/api/User/ChangeUserState',
      editUser: '/api/User/EditUser',
      changeUserLanguage: '/api/User/ChangeUserLanguage',
      updateNewCertAlertFlag: '/api/User/UpdateNewCertAlertFlag',
      updateEmailAlertFlag: '/api/User/UpdateEmailAlertFlag'
  },
  customer: {
    getCustomers: '/api/Customer/GetCustomers',
    changeCustomerLanguage: '/api/Customer/ChangeCustomerLanguage',
    createCustomer: '/api/Customer/CreateCustomer',
    changeCustomerState: '/api/Customer/ChangeCustomerState',
    getH6Customers: '/api/Customer/GetH6Customers',
    getVismaCustomers: '/api/Customer/GetVismaCustomers',
    addMaintainer: '/api/Customer/AddMaintainer',
    getElevatorDetails: '/api/Customer/GetElevatorDetails',
    assignCustomerToElevators: '/api/Customer/AssignCustomerToElevators',
    createNewCustomer:'/api/Customer/CreateNewCustomer',
    customerLookup:'/api/Customer/CustomerLookup',
    checkCustomerName: '/api/Customer/IsCustomerNameExists'
  },
  inspection: {
    getInspections: '/api/Inspection/GetInspections',
    completeInspection: '/api/Inspection/CompleteInspection',
    deleteInspection: '/api/Inspection/DeleteInspection',
    updateInspectionComment: '/api/Inspection/UpdateInspectionComment',
    downloadCertificate: '/api/Inspection/downloadCertificate',
    exportToDocument: '/api/Inspection/ExportToDocument',
    uploadCertificate: '/api/Inspection/UploadCertificate',
    deleteCertificate: '/api/Inspection/DeleteCertificate',
    getExportPreference: '/api/Inspection/GetExportPreference',
    saveExportPreference: '/api/Inspection/SaveExportPreference'
  },
  assessments : {
    getAssessments : '/api/Assessment/GetAssessments',
    deleteAssessments : '/api/Assessment/DeleteAssessment',
    updateAssessments : '/api/Assessment/UpsertAssessment',
    addAssessments : '/api/Assessment/UpsertAssessment',
    getAssessmentColor : '/api/Assessment/GetAssessmentColors'
  }
};

export const supportedBrowsers = {
  chrome: {
    name: 'Chrome',
    version: 56
  },
  edge: {
    name: 'Edge',
    version: 18
  },
  firefox: {
    name: 'Firefox',
    version: 52
  },
  safari: {
    name: 'Safari',
    version: 11
  }
};

export const ApiResponseStatus = {

  Success: 'Success',
  Failure: 'Error'

};

export enum UserState {
  Active = 1,
  Deactivated = 2,
  Deleted = 3,
  InActive = 4,
}

export const types = {
  all: {
    name: 'All',
    value: 'all'
  },
  customer: {
    name: 'Customer',
    value: 'customer'
  },
  serviceCompany: {
    name: 'Service Company',
    value: 'serviceCompany'
  },
  maintainer: {
    name: 'Maintainer',
    value: 'maintainer'
  },
  securityIssues: {
    name: 'Security Issues',
    value: 'securityIssues'
  },
  reinspection: {
    name: 'Reinspection',
    value: 'reinspection'
  }
};

function guid() {
  const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
  let str = '';
  for (let i = 0; i < 18; i++) {
    str = str + ((i == 8 || i == 13 || i == 18 || i == 23) ? '-' : chars[Math.floor(Math.random() * chars.length)]);
  }
  return str;
}

export const expiredTransactionId = '23456';

export const Languages: Language[] = [
  { id: 1, languageName: 'English', languageCode: 'en' },
  { id: 2, languageName: 'Svenska', languageCode: 'sv' },
];
