import { BehaviorSubject, of, throwError, Subject } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './../services/authentication.service';
import { OnDestroy } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
var JwtInterceptor = /** @class */ (function () {
    function JwtInterceptor(authenticationService) {
        this.authenticationService = authenticationService;
        this.isRefreshingToken = false;
        this.tokenSubject = new BehaviorSubject(null);
        this.unsubscribe$ = new Subject();
    }
    JwtInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        //add token in header
        this.authenticationService.currentUser.pipe(takeUntil(this.unsubscribe$)).subscribe(function (x) { return _this.currentUser = x; });
        if (this.currentUser && this.currentUser.refreshToken && this.currentUser.accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Bearer " + this.currentUser.accessToken,
                    RefreshToken: "" + this.currentUser.refreshToken
                }
            });
        }
        return next.handle(request).pipe(catchError(function (error) {
            if (error.status === 401 || error.status === 403 || (error && error.error && typeof error.error.includes !== "undefined" && error.error.includes('204'))) {
                _this.authenticationService.logout().pipe(takeUntil(_this.unsubscribe$))
                    .subscribe(function (data) { _this.authenticationService.removeUser(); location.reload(true); });
            }
            if (error && error.error && typeof error.error.includes != "undefined" && error.error.includes('203')) {
                return _this.handle401Error(request, next);
            }
            return throwError(error);
        }));
    };
    JwtInterceptor.prototype.handle401Error = function (request, next) {
        var _this = this;
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);
            return this.authenticationService.refreshToken().pipe(switchMap(function (token) {
                if (token && token != null) {
                    _this.authenticationService.removeUser();
                    _this.authenticationService.setUser(token);
                    _this.tokenSubject.next(token);
                    request = request.clone({
                        setHeaders: {
                            Authorization: "Bearer " + token.accessToken,
                            RefreshToken: "" + token.refreshToken
                        }
                    });
                    return next.handle(request);
                }
                if (token == null) {
                    _this.authenticationService.logout().pipe(takeUntil(_this.unsubscribe$)).subscribe(function (data) { _this.authenticationService.removeUser(); location.reload(true); });
                }
                return of(_this.authenticationService.logout().pipe(takeUntil(_this.unsubscribe$)).subscribe(function (d) {
                    location.reload(true);
                }));
            }), catchError(function (err) {
                _this.authenticationService.logout().pipe(takeUntil(_this.unsubscribe$)).subscribe(function (data) { localStorage.removeItem("currentUser"); location.reload(true); });
                return throwError(err.error);
            }), finalize(function () {
                _this.isRefreshingToken = false;
            }));
        }
        else {
            this.isRefreshingToken = false;
            return this.tokenSubject
                .pipe(filter(function (token) { return token != null; }), take(1), switchMap(function (token) {
                return next.handle(request);
            }));
        }
    };
    JwtInterceptor.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    JwtInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function JwtInterceptor_Factory() { return new JwtInterceptor(i0.inject(i1.AuthenticationService)); }, token: JwtInterceptor, providedIn: "root" });
    return JwtInterceptor;
}());
export { JwtInterceptor };
