import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserIdleService } from 'angular-user-idle';
import { apiUrl, ApiResponseStatus } from './../../shared/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-user-idle";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, userIdle) {
        this.http = http;
        this.userIdle = userIdle;
        this.currentUserSubject = new BehaviorSubject(this.getUser());
        this.currentUser = this.currentUserSubject.asObservable();
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.login = function (username, password) {
        var _this = this;
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.login, {
            UserName: username,
            Password: btoa(password)
        })
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            if (response.status === ApiResponseStatus.Success && response.data) {
                _this.loginSuccess(response);
            }
            return response;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        var _this = this;
        try {
            return this.http
                .post("" + environment.apiUrl + apiUrl.account.logout, {
                Id: this.getUser().user.id,
                RefreshToken: this.getUser().refreshToken,
                AccessToken: this.getUser().accessToken
            })
                .pipe(map(function (response) {
                if (response.status === ApiResponseStatus.Failure) {
                    return throwError(response.data);
                }
                _this.removeUser();
                _this.currentUserSubject.next(null);
                _this.userIdle.stopWatching();
            }));
        }
        catch (error) {
            this.removeUser();
            this.currentUserSubject.next(null);
            this.userIdle.stopWatching();
        }
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.refreshToken, {
            RefreshToken: this.getUser() ? this.getUser().refreshToken : null,
            AccessToken: this.getUser() ? this.getUser().accessToken : null
        })
            .pipe(map(function (response) {
            if (response.status === ApiResponseStatus.Success && response.data) {
                _this.loginSuccess(response);
            }
            else {
                _this.logout();
            }
            return response.data;
        }, function (error) {
            return error;
        }));
    };
    AuthenticationService.prototype.validateSetPasswordLink = function (token) {
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.checkForgotPassword, {
            String: token
        })
            .pipe(map(function (response) {
            if (response.status === ApiResponseStatus.Failure) {
                return throwError(response.data);
            }
            return response.data;
        }, function (error) { return error; }));
    };
    AuthenticationService.prototype.getTermsAndConditions = function (token) {
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.termsAndConditions, {
            String: token
        })
            .pipe(map(function (response) {
            if (response.status === ApiResponseStatus.Failure) {
                return throwError(response.data);
            }
            return response.data;
        }, function (error) { return error; }));
    };
    AuthenticationService.prototype.forgotPassword = function (email) {
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.forgotPassword, {
            String: email
        }).pipe(map(function (response) {
            if (response.status === ApiResponseStatus.Failure) {
                return throwError(response.data);
            }
        }, function (error) { return error; }));
    };
    AuthenticationService.prototype.setPassword = function (token, password) {
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.setPassword, {
            Token: token,
            Password: btoa(password)
        })
            .pipe(map(function (response) {
            if (response.status === ApiResponseStatus.Failure) {
                return throwError(response.data);
            }
            return response.data;
        }));
    };
    AuthenticationService.prototype.changePassword = function (currentPassword, newPassword) {
        var userid = this.currentUserValue.user.id;
        return this.http
            .post("" + environment.apiUrl + apiUrl.account.changePassword, {
            UserId: userid,
            OldPassword: btoa(currentPassword),
            NewPassword: btoa(newPassword)
        })
            .pipe(map(function (response) {
            console.log(response);
            if (response.status === ApiResponseStatus.Failure) {
                return response;
            }
            return response.data;
        }, function (error) {
            return error;
        }));
    };
    AuthenticationService.prototype.impersonateUser = function (userName, userId) {
        var _this = this;
        return this.http.post("" + environment.apiUrl + apiUrl.account.impersonate, {
            RefreshToken: '',
            User: {
                Id: userId ? userId : '00000000-0000-0000-0000-000000000000',
                UserName: userName,
                ImpersonatedUserName: this.currentUserValue.user.firstName + ' ' + this.currentUserValue.user.lastName
            }
        })
            .pipe(map(function (response) {
            if (response.status === ApiResponseStatus.Failure) {
                return throwError(response.data);
            }
            _this.loginSuccess(response);
            return true;
        }));
    };
    AuthenticationService.prototype.exitImpersonateUser = function () {
        var _this = this;
        var userid = this.currentUserValue.user.impersonateUserId;
        try {
            return this.http.post("" + environment.apiUrl + apiUrl.account.exitImpersonate, {
                RefreshToken: '',
                User: {
                    ImpersonateUserId: userid
                }
            })
                .pipe(map(function (response) {
                if (response.status === ApiResponseStatus.Failure) {
                    return throwError(response.data);
                }
                _this.loginSuccess(response);
                return true;
            }));
        }
        catch (ex) {
            console.log(ex);
        }
    };
    AuthenticationService.prototype.getJwtPayload = function (data) {
        try {
            var jwt = this.getUser().accessToken;
            if (jwt) {
                var jwtData = jwt.split('.')[1];
                var decodedJwtJsonData = window.atob(jwtData);
                return JSON.parse(decodedJwtJsonData)[data];
            }
            return null;
        }
        catch (ex) {
            console.log(ex);
        }
    };
    AuthenticationService.prototype.setUser = function (user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    };
    AuthenticationService.prototype.getUser = function () {
        return JSON.parse(localStorage.getItem('currentUser'));
    };
    AuthenticationService.prototype.removeUser = function () {
        localStorage.removeItem('currentUser');
    };
    AuthenticationService.prototype.getLanguage = function () {
        return localStorage.getItem('language');
    };
    AuthenticationService.prototype.setLanguage = function (language) {
        localStorage.setItem('language', language);
    };
    AuthenticationService.prototype.getDefaultLanguage = function () {
        return environment.defaultLanguage == 'sv' ? 2 : 1;
    };
    AuthenticationService.prototype.loginSuccess = function (response) {
        var data = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.setUser(data); // Setting updated user details to local storage
        this.currentUserSubject.next(data);
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient), i0.inject(i2.UserIdleService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
