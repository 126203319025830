/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "../../node_modules/ngx-toastr/toastr.css.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "./app.component";
var styles_AppComponent = [i0.styles, i1.styles];
var RenderType_AppComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i2.ɵdid(1, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i2.ViewContainerRef, i2.ComponentFactoryResolver, [8, null], i2.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i2.ɵdid(1, 49152, null, 0, i4.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i2.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
