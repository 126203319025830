﻿import { UserInfo } from './../models/UserInfo';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserIdleService } from 'angular-user-idle';
import { apiUrl, ApiResponseStatus } from './../../shared/constants';
import { ApiResponse } from '../models/apiResponse';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserInfo>;
  public currentUser: Observable<UserInfo>;

  constructor(private http: HttpClient,
    private userIdle: UserIdleService) {
    this.currentUserSubject = new BehaviorSubject<UserInfo>(
      this.getUser()
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserInfo {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}${apiUrl.account.login}`, {
        UserName: username,
        Password: btoa(password)
      })
      .pipe(
        map(response => {
          // login successful if there's a jwt token in the response
          if (response.status === ApiResponseStatus.Success && response.data) {

            this.loginSuccess(response);
          }

          return response;
        })
      );
  }

  logout() {
    try {
      return this.http
        .post<ApiResponse>(
          `${environment.apiUrl}${apiUrl.account.logout}`,
          {
            Id: this.getUser().user.id,
            RefreshToken: this.getUser().refreshToken,
            AccessToken: this.getUser().accessToken
          },
        )
        .pipe(map(response => {

          if (response.status === ApiResponseStatus.Failure) {
            return throwError(response.data);
          }

          this.removeUser();
          this.currentUserSubject.next(null);
          this.userIdle.stopWatching();
        }));
    } catch (error) {

      this.removeUser();
      this.currentUserSubject.next(null);
      this.userIdle.stopWatching();
    }
  }

  refreshToken() {
    return this.http
      .post<ApiResponse>(
        `${environment.apiUrl}${apiUrl.account.refreshToken}`,
        {
          RefreshToken: this.getUser() ? this.getUser().refreshToken : null,
          AccessToken: this.getUser() ? this.getUser().accessToken : null
        },
      )
      .pipe(map(response => {

        if (response.status === ApiResponseStatus.Success && response.data) {
          this.loginSuccess(response);
        } else {
          this.logout();
        }

        return response.data as UserInfo;
      },
        error => {
          return error;
        })
      );
  }

  validateSetPasswordLink(token) {
    return this.http
      .post<ApiResponse>(
        `${environment.apiUrl}${apiUrl.account.checkForgotPassword}`,
        {
          String: token
        },
      )
      .pipe(map(response => {

        if (response.status === ApiResponseStatus.Failure) {
          return throwError(response.data);
        }

        return response.data;
      },
        error => error)
      );
  }

  getTermsAndConditions(token) {
    return this.http
      .post<ApiResponse>(
        `${environment.apiUrl}${apiUrl.account.termsAndConditions}`,
        {
          String: token
        },
      )
      .pipe(map(response => {

        if (response.status === ApiResponseStatus.Failure) {
          return throwError(response.data);
        }

        return response.data;
      },
        error => error)
      );
  }

  forgotPassword(email: string) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}${apiUrl.account.forgotPassword}`, {
        String: email
      }).pipe(map(response => {
        if (response.status === ApiResponseStatus.Failure) {
          return throwError(response.data);
        }
      },
        error => error)
      );
  }

  setPassword(token, password) {
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}${apiUrl.account.setPassword}`, {
        Token: token,
        Password: btoa(password)
      })
      .pipe(map(response => {
        if (response.status === ApiResponseStatus.Failure) {
          return throwError(response.data);
        }

        return response.data;
      })
      );
  }

  changePassword(currentPassword, newPassword) {
    const userid = this.currentUserValue.user.id;
    return this.http
      .post<ApiResponse>(`${environment.apiUrl}${apiUrl.account.changePassword}`, {
        UserId: userid,
        OldPassword: btoa(currentPassword),
        NewPassword: btoa(newPassword)
      })
      .pipe(map(response => {

        console.log(response);
        if (response.status === ApiResponseStatus.Failure) {
          return response;
        }
        
        return response.data;
      },
        error => {
          return error;
        })
      );
  }

  impersonateUser(userName: string, userId?: string) {
    return this.http.post<ApiResponse>(`${environment.apiUrl}${apiUrl.account.impersonate}`,
      {
        RefreshToken: '',
        User: {
          Id: userId ? userId : '00000000-0000-0000-0000-000000000000',
          UserName: userName,
          ImpersonatedUserName: this.currentUserValue.user.firstName + ' ' + this.currentUserValue.user.lastName
        }
      })
      .pipe(map(response => {
        if (response.status === ApiResponseStatus.Failure) {
          return throwError(response.data);
        }

        this.loginSuccess(response);

        return true;
      }));
  }

  exitImpersonateUser() {
    const userid = this.currentUserValue.user.impersonateUserId;
    try {
      return this.http.post<ApiResponse>(`${environment.apiUrl}${apiUrl.account.exitImpersonate}`,
        {
          RefreshToken: '',
          User: {
            ImpersonateUserId: userid
          }
        })
        .pipe(map(response => {
          if (response.status === ApiResponseStatus.Failure) {
            return throwError(response.data);
          }

          this.loginSuccess(response);

          return true;
        }));
    } catch (ex) {
      console.log(ex);
    }
  }

  getJwtPayload(data: string) {
    try {
      const jwt = this.getUser().accessToken;
      if (jwt) {
        const jwtData = jwt.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        return JSON.parse(decodedJwtJsonData)[data];
      }
      return null;

    } catch (ex) {
      console.log(ex);
    }
  }

  setUser(user: UserInfo) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  removeUser() {
    localStorage.removeItem('currentUser');
  }

  getLanguage() {
    return localStorage.getItem('language');
  }

  setLanguage(language) {
    localStorage.setItem('language', language);
  }

  getDefaultLanguage() {
    return environment.defaultLanguage == 'sv' ? 2 : 1;
  }

  private loginSuccess(response: ApiResponse) {
    const data = response.data as UserInfo;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    this.setUser(data); // Setting updated user details to local storage
    this.currentUserSubject.next(data);
  }
}
