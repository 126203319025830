import { BehaviorSubject, Observable, of, throwError, Subject } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, filter, finalize, switchMap, take, takeUntil } from 'rxjs/operators';

import { AuthenticationService } from './../services/authentication.service';
import { Injectable, OnDestroy } from '@angular/core';
import { UserInfo } from '../models';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor, OnDestroy {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
    private unsubscribe$: Subject<void> = new Subject();
    currentUser: UserInfo;

    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //add token in header
        this.authenticationService.currentUser.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.currentUser = x);

        if (this.currentUser && this.currentUser.refreshToken && this.currentUser.accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.currentUser.accessToken}`,
                    RefreshToken: `${this.currentUser.refreshToken}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401 || error.status === 403 || (error && error.error && typeof error.error.includes !== "undefined" && error.error.includes('204'))) {
                    this.authenticationService.logout().pipe(takeUntil(this.unsubscribe$))
                    .subscribe(data => { this.authenticationService.removeUser(); location.reload(true); });
                }
                if (error && error.error && typeof error.error.includes != "undefined" && error.error.includes('203')) {
                    return this.handle401Error(request, next);
                }
                return throwError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(switchMap(token => {
                if (token && token != null) {
                    this.authenticationService.removeUser();
                    this.authenticationService.setUser(token);
                    this.tokenSubject.next(token);
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token.accessToken}`,
                            RefreshToken: `${token.refreshToken}`
                        }
                    });
                    return next.handle(request);
                }
                if (token == null) {
                    this.authenticationService.logout().pipe(takeUntil(this.unsubscribe$)).subscribe(data => { this.authenticationService.removeUser(); location.reload(true); });
                }
                return of(<any>this.authenticationService.logout().pipe(takeUntil(this.unsubscribe$)).subscribe(d => {
                    location.reload(true);
                }));
            }),
                catchError(err => {
                    this.authenticationService.logout().pipe(takeUntil(this.unsubscribe$)).subscribe(data => { localStorage.removeItem("currentUser"); location.reload(true); });
                    return throwError(err.error);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
        } else {
            this.isRefreshingToken = false;
            return this.tokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(request);
                    }));
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}